/* eslint-disable no-return-assign */
import { Karma } from "next/font/google";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { impacts } from "../mock/impacts";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function ImpactSection() {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  useEffect(() => {
    const snapEl = document.getElementById("impact-snap");
    if (snapEl) {
      setContainerWidth(snapEl.offsetWidth);
    }
  }, []);

  const scrollLeftOrRight = (dir: string, id = "impact-snap") => {
    // on scrollable snap element od id "hero-snap", scroll right or left full width
    const snapEl = document.getElementById(id);
    if (snapEl && id === "impact-snap") {
      console.log(activeSnap);
      snapEl.scrollLeft +=
        dir === "next" ? containerWidth : containerWidth * -1;
    }
  };

  const [activeSnap, setActiveSnap] = useState(0);

  const imageRef = useRef<any[]>([]);

  useEffect(() => {
    imageRef.current = imageRef.current.slice(0, impacts?.length);
  }, []);

  const onIntersectionObserved = (
    entries: { isIntersecting: any; intersectionRatio: number; target: any }[]
  ) => {
    entries.forEach(
      (entry: {
        isIntersecting: any;
        intersectionRatio: number;
        target: any;
      }) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
          const intersectingIndex = imageRef.current.indexOf(entry.target);
          setActiveSnap(intersectingIndex);
        }
      }
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersectionObserved, {
      threshold: 0.8,
    });
    if (imageRef.current.length > 0) {
      imageRef.current.forEach((item) => {
        observer.observe(item);
      });
    }
  }, [imageRef]);

  return (
    <section
      id="results-section"
      className="relative flex w-full flex-col items-center justify-center bg-white px-4 md:px-8"
    >
      <div className="relative z-50 -mb-16 text-center text-[44px] leading-[100px] md:mb-0 ">
        <h2
          className={`text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className} pt-12`}
        >
          Proven Results. Priceless Impact.
        </h2>
        <p className="pb-10 pt-5 text-[20px]">
          Our software helps organizations build their future by identifying
          superb talent, achieving peak <br />
          performance, saving and reallocating time, and massively cutting down
          direct and indirect costs.
        </p>
      </div>
      <div className="relative z-40 mx-auto flex w-full flex-col items-center gap-8 pb-10 text-white lg:w-max lg:flex-row">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="absolute left-0 z-50 hidden h-12 w-14 transform cursor-pointer text-black transition-all duration-300 ease-in hover:text-[#018906] lg:relative lg:block"
          onClick={() => scrollLeftOrRight("prev")}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>

        <div
          id="impact-snap"
          className="no-scrollbar relative z-30 flex h-[380px] w-screen overflow-scroll bg-white md:h-auto md:w-[900px] md:snap-x md:snap-mandatory"
        >
          {impacts.map((impact, j) => (
            <div
              key={impact.id}
              ref={(el) => (imageRef.current[j] = el)}
              className="relative z-50 my-auto flex h-[480px] w-[100vw] min-w-[100vw] flex-col gap-4 bg-transparent p-8 pb-0 pl-16 md:w-[900px] md:min-w-[900px] md:snap-start md:snap-always md:rounded-[5px] md:bg-[#ffffff] md:p-16"
            >
              {impact.image && (
                <Image
                  src={impact.image}
                  fill
                  alt="KnackApp Impact"
                  className="w-full object-contain object-center"
                />
              )}
            </div>
          ))}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="absolute right-0 z-50 hidden h-12 w-14 transform cursor-pointer text-black transition-all duration-300 ease-in hover:text-[#018906] lg:relative lg:block"
          onClick={() => scrollLeftOrRight("next")}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
      <div className="relative z-50 mt-0 flex justify-center">
        {impacts.map((impact, index) => (
          <span
            key={impact.id}
            className={`mx-2 h-3 w-3 rounded-full ${
              index === activeSnap ? "bg-green" : "bg-gray-400"
            }`}
          >
          </span>
        ))}
      </div>
    </section>
  );
}
