/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-return-assign */

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Karma } from "next/font/google";

import EducationImage from "assets/svgs/education.png";
import HarvardLogo from "assets/svgs/harvard-logo.png";
import UsaAidImage from "assets/svgs/usaid.png";
import { AnimatePresence } from "framer-motion";
import LandingNavBar from "./navbar";
import { CopyDesktop } from "./copyDesktopLink";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function LandingHero() {
  const [showDesktopModal, setShowDesktopModal] = useState(false);

  return (
    <section
      className={`flex h-auto min-h-screen  w-screen flex-col justify-between overflow-hidden bg-white pb-16`}
    >
      <div className="flex flex-col">
        <LandingNavBar />
        <h1
          className={`lg:mt-30 mt-10 px-4 text-center text-[40px] font-bold  text-[#191919] md:mt-20  xl:mt-28 ${font.className}`}
        >
          The most effective way to spot the highest potential talents
        </h1>
        <p className="mt-2 text-center text-[22px] font-medium text-[#585858]">
          Our award-winning games unlock human potential analytics that deliver
          unprecedented results
        </p>
        <Link
          href="/signup/business"
          onClick={(e) => {
            if (window.innerWidth < 768) {
              e.preventDefault();
              setShowDesktopModal(true);
            }
          }}
          className="animateLinkAsButton mx-auto mt-8 rounded bg-[#018906] px-12 py-2 font-semibold text-white"
        >
          Spot superstars
        </Link>
      </div>

      <div className="relative mx-auto mt-8 h-[60vh] min-h-[500px] w-full max-w-[90vw] self-end 2xl:max-w-[80vw]">
        <Image
          src="https://res.cloudinary.com/knackapp/image/upload/c_scale,w_1440/v1688993408/static/2a571c01e812b9d08b5110fa13b32681.png"
          alt="How will you identify the best talent?"
          fill
          quality={100}
          priority
          className="h-full w-full object-cover object-center"
        />

        <div className="absolute bottom-2 hidden w-full flex-col items-center  justify-center px-4 md:inset-x-0 md:flex md:flex-row md:justify-around lg:flex">
          <div className="flex flex-col gap-2 text-lg text-white">
            <p className="text-base font-medium">
              “Innovation shaping the future of education”
            </p>
            <div className="flex gap-2">
              <Image
                src={EducationImage}
                height={100}
                width={250}
                alt="Innovation shaping the future of education"
                className="object-contain"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 text-lg text-white">
            <p className="text-base font-medium">
              “Groundbreaking technology innovation”
            </p>
            <div className="flex gap-2">
              <Image
                src={HarvardLogo}
                height={100}
                width={220}
                alt="“Innovation shaping the future of education”"
                className="object-contain"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 text-lg text-white">
            <p className="text-base font-medium">
              Recommended by the US Government
            </p>
            <div className="flex  gap-2">
              <Image
                src={UsaAidImage}
                height={100}
                width={200}
                alt="Recommended by the US Government"
                className="object-contain"
              />
            </div>
          </div>{" "}
        </div>
      </div>

      <Link
        href="/how-it-works"
        className="mt-8 text-center text-xl font-medium text-[#018906] underline"
      >
        How does it work?
      </Link>
      <AnimatePresence>
        {showDesktopModal && (
          <CopyDesktop
            link="signup/business"
            handleCloseModal={() => setShowDesktopModal(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
}
