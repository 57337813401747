import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import { useSelector } from "react-redux";
import SharedHeader from "global/head/header";
import { useRouter } from "next/router";
import RedesignedLanding from "components/landing-redesign/main";
import { PWANudge } from "components/landing-redesign/navbar";
import Head from "next/head";
import NewsletterModal from "components/landing-redesign/newsletterModal";

const isInStandaloneMode = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  // @ts-ignore
  window.navigator.standalone ||
  document.referrer.includes("android-app://");

export default function Home() {
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  // @ts-ignore
  const token = useSelector((state) => state.auth.token);

  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const [hasSeenNewsletter, setHasSeenNewsletter] = useState(true);

  const [showInstallPWANudge, setShowInstallPWANudge] = useState(false);
  const [hasSeenNudge, setHasSeenNudge] = useState(true);

  useEffect(() => {
    // check if device supports PWA
    const supportsPWA = "serviceWorker" in navigator;
    const isFirefox = navigator.userAgent.includes("Firefox");
    // check if user has seen nudge
    if (
      !hasSeenNudge &&
      localStorage.getItem("hasSeenNudge") === null &&
      supportsPWA &&
      !isInStandaloneMode() &&
      !isFirefox &&
      window.innerWidth > 768
    ) {
      setTimeout(() => {
        // record time
        localStorage.setItem("hasSeenNudge", Date.now().toString());
        setShowInstallPWANudge(true);
      }, 5000);
    }
  }, [hasSeenNudge]);

  useEffect(() => {
    // get newsletterTime time and see if it's been 6hrs
    const nudgeTime = localStorage.getItem("hasSeenNudge");
    if (nudgeTime) {
      const time = new Date().getTime();
      const diff = time - Number(nudgeTime);
      // if it's been 6hrs
      if (diff > 21600000) {
        setHasSeenNudge(false);
      } else {
        setHasSeenNudge(true);
      }
    } else {
      setHasSeenNudge(false);
    }
  }, []);

  useEffect(() => {
    // get newsletterTime time and see if it's been 6hrs
    const newsletterTime = localStorage.getItem("newsletterTime");
    if (newsletterTime) {
      const time = new Date().getTime();
      const diff = time - Number(newsletterTime);
      // if it's been 6hrs
      if (diff > 21600000) {
        setHasSeenNewsletter(false);
      } else {
        setHasSeenNewsletter(true);
      }
    } else {
      setHasSeenNewsletter(false);
    }
  }, []);

  useEffect(() => {
    if (
      !hasSeenNewsletter &&
      localStorage.getItem("hasSeenNewsletter") === null
    ) {
      setTimeout(() => {
        // record time
        localStorage.setItem("newsletterTime", Date.now().toString());
        setShowNewsletterModal(true);
      }, 15000);
    }
  }, [hasSeenNewsletter]);

  const router = useRouter();

  useEffect(() => {
    if (token && userInfo?.role === "business" && userInfo?.handle) {
      router.push("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, token]);

  return (
    <>
      <SharedHeader />
      <Head>
        <link
          rel="preload"
          href="https://res.cloudinary.com/knackapp/image/upload/c_scale,w_1440/v1688993408/static/2a571c01e812b9d08b5110fa13b32681.png"
          as="image"
        />
      </Head>
      <main className="">
        <RedesignedLanding />
        <AnimatePresence>
          {showNewsletterModal && (
            <NewsletterModal closeModal={() => setShowNewsletterModal(false)} />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showInstallPWANudge && (
            <PWANudge closeModal={() => setShowInstallPWANudge(false)} />
          )}
        </AnimatePresence>
      </main>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {},
  };
}
