import React, { useState } from "react";

import { Karma } from "next/font/google";
import Image from "next/image";

import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import GameImageModal from "./gameImageModel";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

const Games = [
  {
    delay: 0.1,
    src: "https://res.cloudinary.com/knackapp/image/upload/v1689077296/static/games/b7e0f38537726a88d8b97c1989308793.png",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto/v1689078223/static/games/a684d9e3dc61f46ec6edefd9f06e6d32.png",
  },
  {
    delay: 0.1,
    src: "https://res.cloudinary.com/knackapp/image/upload/v1689077296/static/games/cc38782c9bc5e071a17b4a56337bf5a0.png",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto/v1689078223/static/games/1b551427500c82fb76e59e6b0fedc04a.png",
  },
  {
    delay: 0.2,
    src: "https://res.cloudinary.com/knackapp/image/upload/v1689077296/static/games/1eb5562924e5d6c99c375343d9c0e3f2.png",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/q_60,r_0/v1689758126/static/games/a061348539da106f1a2fbeea33dcb6c2.png",
  },
  {
    delay: 0.3,
    src: "https://res.cloudinary.com/knackapp/image/upload/v1689077296/static/games/2c10588d6cbef7405fd535981dc24faf.png",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto/v1689078224/static/games/70e85cccf3c78c8a4dc9054349bbc098.png",
  },
];

export default function FunGames() {
  const [showGameImageModal, setShowGameImageModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState("");
  const handleGameClick = (gameSrc: string) => {
    setSelectedGame(gameSrc);
    setShowGameImageModal(true);
  };
  return (
    <section
      id="games-section"
      className="flex w-full flex-col items-center justify-center gap-6 bg-white px-6 py-[4rem] pb-[5rem]"
    >
      <h2
        className={`text-center text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className}`}
      >
        Fun Games Everyone Loves!
      </h2>
      <div className="flex flex-wrap justify-center md:gap-4">
        {Games.map((game, index) => (
          <OneGame
            key={index}
            delay={game.delay}
            src={game.src}
            onClick={() => handleGameClick(game.image)}
          />
        ))}
      </div>
      <p className="max-w-3xl text-center text-base font-medium text-[#585858]">
        Our special games combine behavioral science, data analytics, and
        artificial intelligence. They’re made for everyone, regardless of age,
        digital literacy, or education level.{" "}
        <span className="text-[#018906]">Click the icons to see the games</span>{" "}
        and also see{" "}
        <Link href="/gallery" className="text-[#018906] underline">
          people at play around the world.
        </Link>
      </p>
      <AnimatePresence>
        {showGameImageModal && selectedGame && (
          <GameImageModal
            closeModal={() => setShowGameImageModal(false)}
            selectedGame={selectedGame}
          />
        )}
      </AnimatePresence>
    </section>
  );
}

function OneGame({
  src,
  delay,
  onClick,
}: {
  src: string;
  delay: number;
  onClick: () => void;
}) {
  return (
    <motion.div
      initial={{ y: 15, opacity: 0.5 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.3, ease: "easeIn", delay }}
      className="relative h-[180px] w-[180px] rounded-lg md:h-[200px] md:w-[200px]"
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.3, delay: 0, ease: "easeOut" },
      }}
      onClick={onClick}
    >
      <Image src={src} fill alt="KnackApp Games" />
    </motion.div>
  );
}
