import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import { Karma } from "next/font/google";
import { AnimatePresence, motion } from "framer-motion";
import TalentDimensions from "components/gamification/dimensions";
import Testimonials from "components/testimonials";
import { CopyDesktop } from "./copyDesktopLink";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function KnackFeatures() {
  const [showDimensions, setShowDimensions] = React.useState(false);
  const [showTestimonials, setShowTestimonials] = React.useState(false);
  return (
    <section
      id="analytics-section"
      className="flex flex-col items-center justify-center gap-8 bg-white px-4 md:px-8"
    >
      <h2
        className={`text-center text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className}`}
      >
        Here’s How It’ll Change Your Game
      </h2>

      <div className="mb-16 flex flex-col gap-16 md:pl-10">
        <KnackFeature
          title="Use data to make your mark"
          description={
            <p className="text-lg font-medium text-[#191919]  md:w-[80%]">
              Recruiting and hiring? Developing talent? Providing career
              guidance? Coaching?{" "}
              <span
                onClick={() => setShowTestimonials(true)}
                className="cursor-pointer text-[#018906] underline"
              >
                Check out these key insights from talent executives, educators,
                career coaches, and government officials.
              </span>
            </p>
          }
          btnText="Make your mark!"
          imageLink="https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689183526/static/business/snaps/836640716b621a8521a6a15f2de3703e.png"
          reverse
          delay={0}
        />

        <KnackFeature
          title="Use AI to identify superstars!"
          description={
            <p className="text-lg font-medium text-[#191919] md:w-[80%]">
              Spot people who’ve got the talent X-factor that’ll give your
              business its unique X-factor advantage. Superstars are found
              everywhere, you just have to look.{" "}
              <span
                onClick={() => setShowDimensions(true)}
                className="cursor-pointer text-[#018906] underline"
              >
                See the key human factors that drive business success.
              </span>
            </p>
          }
          btnText="Identify superstars!"
          imageLink="https://res.cloudinary.com/knackapp/image/upload/v1689183643/static/business/snaps/de017b16626169be84d0c93b81667d2a.png"
          delay={0.1}
        />

        <KnackFeature
          title="Pinpoint personal gaps"
          description={
            <p className="text-lg font-medium text-[#191919] md:w-[80%]">
              Gaps are natural, and even super talented people got some. They
              can become a speed bump or a sinkhole, depending on how you manage
              them. Use this intelligence to identify the right fit and help
              people become their best.
            </p>
          }
          btnText="Don’t get blindsided"
          imageLink="https://res.cloudinary.com/knackapp/image/upload/v1689183703/static/business/snaps/5994cdee204e4b9092c221e4c24046a1.png"
          reverse
          delay={0.2}
        />

        <KnackFeature
          title="Identify priceless potential"
          description={
            <p className="text-lg font-medium text-[#191919] md:w-[80%]">
              Say goodbye to the traditional{" "}
              <a
                rel="noreffer noreferrer"
                target="_blank"
                href="https://knackapp.substack.com/p/traditional-career-and-leadership"
                className="cursor-pointer text-[#018906] underline"
              >
                linear leadership ladders
              </a>
              , and use human potential analytics to identify promising future
              leaders. Cut through nepotism, agism, and favoritism that
              undermine your business.
            </p>
          }
          btnText="Identify priceless potential"
          imageLink="https://res.cloudinary.com/knackapp/image/upload/v1689183739/static/business/snaps/db5e4f8aba73039f0f78116868160fc8.png"
          delay={0.3}
        />

        <KnackFeature
          title="Find personal & cultural fit"
          description={
            <p className="text-lg font-medium text-[#191919] md:w-[80%] ">
              Is he or she a good fit? Answering this critical question used to
              be tricky, but not any more. Use data to determine what and where
              would be the best fit for talented people. When they fit and
              thrive, it’s a win-win.
            </p>
          }
          btnText="Find the fit"
          imageLink="https://res.cloudinary.com/knackapp/image/upload/v1689183786/static/business/snaps/1996203777644eff701e09b75837b456.png"
          reverse
          delay={0.4}
        />
      </div>
      <AnimatePresence>
        {showDimensions && (
          <TalentDimensions close={() => setShowDimensions(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showTestimonials && (
          <Testimonials close={() => setShowTestimonials(false)} />
        )}
      </AnimatePresence>
    </section>
  );
}

function KnackFeature({
  title,
  description,
  btnText,
  imageLink,
  reverse,
  delay,
}: {
  title: string;
  description: React.ReactNode;
  btnText: string;
  imageLink: string;
  reverse?: boolean;
  delay: number;
}) {
  const [showDesktopModal, setShowDesktopModal] = useState(false);
  return (
    <section
      className={` flex flex-col items-center justify-between  gap-16 text-center lg:mx-auto lg:flex lg:max-w-[1280px] lg:text-start ${
        reverse ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      <div className="relative h-[260px] w-[100%] md:h-[400px] md:w-[700px] md:min-w-[700px] lg:-ml-12">
        <Image
          src={imageLink}
          alt={title}
          width={700}
          height={400}
          quality={100}
          sizes="(max-width: 700px) 100vw, 700px"
        />
      </div>
      <motion.div
        initial={{ y: 20 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 0.3, ease: "easeIn", delay }}
        viewport={{ amount: 1, once: true }}
        className={`-mt-10 flex flex-col gap-6`}
      >
        <h3 className="mt-8 text-center text-2xl font-semibold text-[#191919] md:mt-4 md:text-3xl  lg:mt-0 lg:text-start">
          {title}
        </h3>
        {description}
        <Link
          href="/signup/business"
          onClick={(e) => {
            if (window.innerWidth < 768) {
              e.preventDefault();
              setShowDesktopModal(true);
            }
          }}
          className="animateLinkAsButton md:mx-18 mx-auto w-max rounded bg-[#018906] px-12 py-2 font-semibold text-white lg:mx-0 "
        >
          {btnText}
        </Link>
      </motion.div>
      <AnimatePresence>
        {showDesktopModal && (
          <CopyDesktop
            link="signup/business"
            handleCloseModal={() => setShowDesktopModal(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
}
