import React from "react";
import { Karma } from "next/font/google";
import Link from "next/link";
import Image from "next/image";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});
const ResearchData = [
  {
    title: "Shell case study on spotting superstar innovators",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,w_800/v1698138808/static/research/v2/d4e69734b9f5d20f3c4b08a365e2a8ec.png",
    link: "https://res.cloudinary.com/knackapp/image/upload/v1698071669/static/research/documents/8226d6ce9cd8ae091632bdfc01905537.pdf",
  },
  {
    title: "NYU research on identifying the high potential surgeons",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,w_800/v1698138807/static/research/v2/2767d8eedba83a454a3f1d38a74bbea5.png",
    link: "https://res.cloudinary.com/knackapp/image/upload/v1698059162/static/research/documents/18856db11db7c94359350fb20180a2fc.pdf",
  },
  {
    title: "US Government report on identifying youth potential",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,w_800/v1698138807/static/research/v2/ad2a55d3522916d55ebf24b991e870ca.png",
    link: "https://res.cloudinary.com/knackapp/image/upload/v1698059160/static/research/documents/341907966153ac407563d5d4c1cb350d.pdf",
  },
  {
    title: "Rockefeller Foundation research on hiring high potential youth",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,w_800/v1698142745/static/research/v2/7a11c2ba7c420690369413c2107bf482.png",
    link: "https://res.cloudinary.com/knackapp/image/upload/v1698059165/static/research/documents/5b2674742f4abca74b446b1079283180.pdf",
  },
  {
    title: "Harvard research on identifying hidden diamonds in the rough",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/f_auto,w_800/v1698138804/static/research/v2/17b959e4ac1a21a0582f66601894319f.png",
    link: "https://res.cloudinary.com/knackapp/image/upload/v1698059168/static/research/documents/343cee7cc350756d4124f0e7231ae1fa.pdf",
  },
];

export default function KnackResearchReport() {
  return (
    <section className="mx-auto my-10 flex flex-col gap-10 bg-white">
      <div className="flex flex-col gap-0">
        <h2
          className={`${font.className} text-center text-[30px] font-bold text-[#191919] md:text-[44px]`}
        >
          Popular Case Studies & Research Reports
        </h2>
        <div className="mb-10 mt-4 flex flex-col items-center justify-center gap-0 text-[16px] md:flex-row md:gap-2 md:text-[20px]">
          <Link
            href="/publications"
            className={`text-center font-medium text-[#018906] underline `}
          >
            Explore our Knowledge Center
          </Link>

          <span>and</span>
          <Link
            href="/books"
            className={`text-center text-[16px]  font-medium text-[#018906] underline md:text-[20px]`}
          >
            browse the books
          </Link>
        </div>
      </div>

      {ResearchData.map((research) => (
        <KnackResearch
          key={research.title}
          title={research.title}
          image={research.image}
          link={research.link}
        />
      ))}
    </section>
  );
}

function KnackResearch({
  title,
  link,
  image,
}: {
  title: string;
  link: string;
  image: string;
}) {
  return (
    <section className="mx-auto mb-8 flex w-full flex-col items-center justify-between bg-white md:max-w-5xl">
      <div className="flex w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-between gap-10 px-8 md:flex md:flex-row md:gap-40">
          <div className="mb-1 w-[100%] md:mb-4  md:w-[40%]">
            <h3 className="flex w-full flex-col text-center text-[30px] font-[500] leading-10 text-[#191919] md:text-start">
              {title}
            </h3>
            <div className="mx-auto mt-5 w-max md:mx-0">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="download w-max rounded border-2 border-[#018906] px-12 py-2  text-[#018906] transition-all duration-300 ease-in"
              >
                Download
              </a>
            </div>
          </div>

          <Image
            src={image}
            alt={title}
            width={400}
            className={`h-auto max-h-[300px] w-full md:max-w-[370px] md:object-contain`}
            height={230}
          />
        </div>
      </div>
    </section>
  );
}
