import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import Image from "next/image";

export default function GameImageModal({
  closeModal,
  selectedGame,
}: {
  closeModal: () => void;
  selectedGame: string;
}) {
  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="fixed inset-0 z-[130] flex h-full w-full items-center justify-center bg-black/30 backdrop-blur-sm"
      id="backdrop"
      onClick={(e) => (e.target === e.currentTarget ? closeModal() : null)}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.5 } }}
        transition={{ duration: 0.3, ease: [0.25, 0.1, 0.25, 1.0] }}
        className="relative z-50 flex h-[80vh] w-full rounded-lg bg-white md:w-[79vw] "
      >
        <div
          onClick={() => {
            closeModal();
          }}
          className="absolute right-2 top-2 z-50 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gray-500 md:-right-12 md:-top-6"
        >
          <IoClose className="" color="#fff" size={25} />
        </div>
        {selectedGame && (
          <Image
            src={selectedGame}
            fill
            alt="Selected Game"
            className="object-contain md:object-cover"
          />
        )}
      </motion.div>
    </div>
  );
}
