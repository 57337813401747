import React, { useState } from "react";
import { Karma } from "next/font/google";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { CopyDesktop } from "./copyDesktopLink";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function Pricing() {
  const [showDesktopModal, setShowDesktopModal] = useState(false);
  return (
    <section className="w-screen bg-white px-8 md:py-[6rem]">
      <div
        id="pricing"
        className="flex h-full w-full flex-col items-center justify-center gap-9 md:px-4"
      >
        <div className={`flex flex-col gap-4 text-center md:mt-0`}>
          <h2
            className={`text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className}`}
          >
            A Perfect Price for Every Need
          </h2>
        </div>
        <div className="mb-8 flex w-full flex-col justify-center gap-4 md:grid md:w-fit md:grid-cols-2 md:justify-items-center lg:flex lg:w-full lg:flex-row">
          <OneCard
            name="Pro"
            delay={0.1}
            monthly="15"
            yearly="150"
            saved={15 * 12 - 150}
            offerings={[
              "Access to all talent templates",
              "Access to all features",
              "Access to branding features",
              "1 Reports",
              "Reports available instantly",
              "PDF report downloads",
            ]}
            selectedOption={"Monthly"}
            setShowDesktopModal={() => setShowDesktopModal(true)}
          />
          <OneCard
            name="Plus"
            delay={0.2}
            monthly="49"
            yearly="490"
            saved={49 * 12 - 490}
            offerings={[
              "Access to all talent templates",
              "Access to all features",
              "Access to branding features",
              "5 Reports",
              "Reports available instantly",
              "PDF report downloads",
            ]}
            selectedOption={"Monthly"}
            setShowDesktopModal={() => setShowDesktopModal(true)}
          />
          <OneCard
            name="Premium"
            delay={0.3}
            monthly="125"
            yearly="1250"
            saved={125 * 12 - 1250}
            offerings={[
              "Access to all talent templates",
              "Access to all features",
              "Access to branding features",
              "15 Reports",
              "Reports available instantly",
              "PDF report downloads",
            ]}
            selectedOption={"Monthly"}
            setShowDesktopModal={() => setShowDesktopModal(true)}
          />
        </div>

        <div className="shadow-enterprise flex max-w-[650px] flex-col gap-4 rounded-[25px] bg-white p-8">
          <h3
            className={`${font.className} text-[38px] font-bold leading-[100%] text-[#018906]`}
          >
            Enterprise
          </h3>
          <p className="text-[20px] font-medium text-[#191919]">
            Need a license to use our platform in talent acquisition, talent
            development, or talent management?
          </p>
          <a
            href="mailto:business@knackapp.com"
            className="h-max w-max self-end rounded-lg bg-[#FF546B] px-8 py-2.5 font-semibold text-white"
          >
            Contact Sales
          </a>
        </div>
      </div>
      <AnimatePresence>
        {showDesktopModal && (
          <CopyDesktop
            link="signup/business"
            handleCloseModal={() => setShowDesktopModal(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
}

export function OneCard({
  name,
  monthly,
  yearly,
  offerings,
  delay,
  fromDashboard,
  highestValue,
  openModal,
  isLoading,
  selectedOption,
  isCurrentPlan,
  openPortal,
  saved,
  setShowDesktopModal,
}: {
  monthly: string;
  yearly: string;
  offerings: string[];
  name: string;
  delay: number;
  fromDashboard?: boolean;
  highestValue?: boolean;
  isLoading?: boolean;
  openModal?: () => void;
  selectedOption: string;
  isCurrentPlan?: boolean;
  openPortal?: () => void;
  saved: number;
  setShowDesktopModal?: () => void;
}) {
  return (
    <motion.div
      initial={{ y: 30 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.3, delay, ease: "easeOut" }}
      viewport={{ once: true, amount: 1 }}
      className={`relative flex  w-full flex-col gap-2 rounded-3xl bg-white p-4 pt-8 leading-[100%] md:w-[290px] ${
        fromDashboard ? "pricing-shadow" : "pricing-shadow shadow-none"
      }`}
    >
      {highestValue && (
        <span className="nicePurpleGradient absolute -top-4 left-1/2 w-4/5 -translate-x-1/2 rounded-[20px] px-6 py-2 text-center text-[14px] font-semibold text-white">
          BEST VALUE
        </span>
      )}
      <h3 className={`text-[33px] font-bold text-[#018906] ${font.className}`}>
        {name}
      </h3>
      {isCurrentPlan && (
        <span className="nicePurpleGradientText absolute right-6 top-12 text-lg font-medium">
          Current plan
        </span>
      )}

      <p className="text-[13px] font-bold text-[#191919]">
        {selectedOption === "Yearly" && (
          <span className="mr-2 text-[34px] font-medium text-[#A6A6A6] line-through">
            $
            {selectedOption === "Yearly"
              ? parseInt(yearly, 10) + saved
              : monthly}
          </span>
        )}
        <span className="text-[40px]">
          ${selectedOption === "Yearly" ? yearly : monthly}
        </span>{" "}
        {selectedOption === "Yearly" ? "per year" : "per month"}
      </p>
      <span className="pale-green hidden rounded-lg px-6 py-2 text-sm font-medium">
        {selectedOption === "Yearly"
          ? `Save $${saved} with annual plan`
          : "Get 2 months free with annual plan"}
      </span>

      <div className="mb-4 flex flex-col gap-1">
        {offerings.map((offering, i) => (
          <p
            key={i}
            className={`xl1:text-[15px] flex items-center gap-2 text-sm ${
              i === 3
                ? name === "Platinum"
                  ? "font-bold text-[#2B2A2A]"
                  : "font-bold text-[#2B2A2A]"
                : "font-medium text-[#2B2A2A]"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#018906"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
            {offering}
          </p>
        ))}
      </div>

      <div className="mx-auto w-[100%]">
        <div className="flex w-full flex-col items-center justify-center gap-2 text-center">
          <Link
            href={
              !fromDashboard
                ? `/signup/business?utm_source=landing&utm_medium=pricing&utm_campaign=${selectedOption.toLowerCase()}&plan=${name}&cycle=${selectedOption.toLowerCase()}`
                : "/dashboard"
            }
            onClick={(e) => {
              if (window.innerWidth < 768) {
                e.preventDefault();
                setShowDesktopModal && setShowDesktopModal();
              }

              if (isLoading) {
                e.preventDefault();
                return;
              }
              if (fromDashboard) {
                e.preventDefault();
                if (!isCurrentPlan) {
                  openModal && openModal();
                } else {
                  openPortal && openPortal();
                }
              }
            }}
            className={`${isLoading && "opacity-50"} ${
              isCurrentPlan ? "flex" : "flex"
            } h-[45px] w-full items-center justify-center rounded-3xl border-[1px] border-[#018906] bg-transparent text-[18px] font-semibold text-[#018906]`}
          >
            {fromDashboard
              ? `${isCurrentPlan ? "Manage" : "Select"}`
              : "Start for free"}
          </Link>
          {!fromDashboard && (
            <span className={`text-[13px] font-medium text-[#A6A6A6]`}>
              No credit card needed
            </span>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export function PricingOptions({
  selectedOption,
  setSelectedOption,
  fromDashboard,
}: {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  fromDashboard?: boolean;
}) {
  return (
    <div className="flex w-full justify-center">
      <OneOption
        text="Monthly Plan"
        isSelected={selectedOption === "Monthly"}
        selectOption={() => setSelectedOption("Monthly")}
        customStyles="rounded-l-md"
        fromDashboard={fromDashboard}
      />
      <OneOption
        text="Yearly Plan"
        isSelected={selectedOption === "Yearly"}
        selectOption={() => setSelectedOption("Yearly")}
        customStyles="rounded-r-md"
        fromDashboard={fromDashboard}
      />
    </div>
  );
}

function OneOption({
  text,
  isSelected,
  selectOption,
  customStyles,
  fromDashboard,
}: {
  text: string;
  isSelected: boolean;
  selectOption: () => void;
  customStyles?: string;
  fromDashboard?: boolean;
}) {
  return (
    <div
      onClick={() => selectOption()}
      className={`nicePurpleGradient flex h-[38px] w-[243px] cursor-pointer items-center justify-center ${customStyles}`}
    >
      <div
        className={`flex h-[33px] w-[238px] items-center justify-center ${customStyles} ${
          isSelected
            ? "nicePurpleGradient text-white"
            : `${fromDashboard ? "bg-white" : "bg-black"} text-[#824AFF]`
        }`}
      >
        <p className="text-[15px] font-semibold">{text}</p>
      </div>
    </div>
  );
}
