import React from "react";
import Image from "next/image";

import { Karma } from "next/font/google";
import { AnimatePresence } from "framer-motion";
import FormModal from "./contactUsModal";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function ContactUs() {
  const [showContactUs, setShowContactUs] = React.useState(false);
  return (
    <section className="flex flex-col items-center justify-between bg-[#0C380E] px-8 py-10 pb-0 md:flex-row md:px-20 md:py-16">
      <div className="flex flex-col gap-6">
        <h2
          className={`text-[40px] font-bold text-white md:text-[60px] ${font.className}`}
        >
          Need help?
        </h2>
        <button
          onClick={() => setShowContactUs(true)}
          className={`animateLinkAsButton h-[55px] w-[291px] place-content-center rounded-[25px] bg-[#018906] text-[20px] font-bold text-white`}
        >
          Send a message
        </button>
      </div>
      <div className="relative mt-0 h-[380px] w-[100%] md:mt-0 md:w-[700px]">
        <Image
          src="https://res.cloudinary.com/knackapp/image/upload/v1689163775/static/research/v2/31182779ece260e1625b6c42cf54ad2f.png"
          alt="Contact Us"
          fill
          className="absolute object-contain"
        />
      </div>
      <AnimatePresence>
        {showContactUs && (
          <FormModal
            showModal={showContactUs}
            closeModal={() => setShowContactUs(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
}
