export const impacts = [
    {
      id: 0,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317642/static/impact/404d82d6af52dffb1053848f0cbe9a8a.png",
    },
    {
      id: 1,
      image: "https://res.cloudinary.com/knackapp/image/upload/f_auto,q_100/v1689335263/static/impact/97bd4f4ad0ef61d1e9510a731c718ba9.png",
    },
    {
      id: 2,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/2d1cae4bec938d3664b8ca0258324bed.png",

    },
    {
      id: 3,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/74cae95d80d2d4b9fc3f973191083b5e.png",
    },
    {
      id: 4,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/09fb044866ac3708b8489c1b8a500702.png",

    },
    {
      id: 5,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/8a377ff874ce7436b670fdb7548051b6.png",
    },
    {
      id: 6,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/478b550b8c5ef6421ed5b1734943af2a.png",
    },
    {
      id: 7,
      image: "https://res.cloudinary.com/knackapp/image/upload/v1689317641/static/impact/a6f1a40476c83be33e8801d1d5ec2eca.png",
    },
  ];