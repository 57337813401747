import React from "react";
import Image from "next/image";

import { motion } from "framer-motion";

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => (
  <div className="modal fixed inset-0 z-50 flex items-center justify-center bg-black/70">
    <motion.div
      initial={{ scale: 0.8, opacity: 0.7 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.5 } }}
      transition={{ duration: 0.3, ease: [0.25, 0.1, 0.25, 1.0] }}
      className="modal-content relative  h-[70vh] min-h-[500px] w-[85vw] rounded-[15px] bg-white px-5 py-3"
    >
      <div
        onClick={() => {
          onClose();
        }}
        className="absolute -right-4 -top-12 z-50 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gray-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#ffffff"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      {children}
    </motion.div>
  </div>
);

export function CopyDesktop({
  handleCloseModal,
  link,
}: {
  handleCloseModal: () => void;
  link: string;
}) {
  const [hasCopied, setHasCopied] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(`${window.location.origin}/${link}`);
    setHasCopied(true);
  };

  return (
    <Modal onClose={handleCloseModal}>
      <div className="flex h-full flex-col items-center justify-between gap-8 py-8">
        <Image
          src={"/logo.png"}
          alt={"Knack Logo"}
          width={100}
          height={100}
          className="object-contain"
          quality={100}
        />
        <h2 className="mb-2 text-center text-2xl font-medium text-[#2B2A2A]">
          Please continue on desktop or laptop to get the full experience
        </h2>

        <button
          onClick={() => handleCopy()}
          className="w-full rounded-[10px] bg-[#018906] px-10 py-3 text-lg font-semibold text-white"
        >
          {hasCopied ? "Copied!" : "Copy this link"}
        </button>
      </div>
    </Modal>
  );
}
