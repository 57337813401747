import React from "react";

import { Karma } from "next/font/google";
import Image from "next/image";

import { motion } from "framer-motion";
import Link from "next/link";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function GoodCompany() {
  return (
    <section className="bg-white px-1 md:px-8 md:py-[4rem] md:pt-0">
      <div className="flex h-full w-full flex-col items-center justify-center gap-16 p-4">
        <div className={`mt-16 flex flex-col gap-4 text-center md:mt-0`}>
          <h2
            className={`text-[28px] font-bold leading-[100%] tracking-normal text-[#191919] lg:text-[38px] ${font.className}`}
          >
            Praised by Experts{" "}
          </h2>
        </div>

        <div className="drop-goodC flex flex-col gap-20 rounded-xl bg-white p-8 py-12 pb-6">
          <OneSection
            delay={0.1}
            image="https://res.cloudinary.com/knackapp/image/upload/f_auto,q_auto:good/v1658826067/static/books/rxtkhx3e0pwz5bpj9v8c.png"
            desc={`“Michael Lewis wrote a provocative book, Moneyball, describing how the Oakland Athletics used an analytics and evidence-based approach, instead of judgment of spot scouts, to create a powerful baseball team. KnackApp is using the same principles to transform talent management. Leading global companies are finding this technology highly effective.”`}
            from="Harvard Business School"
          />
          <OneSection
            delay={0.2}
            image="https://res.cloudinary.com/knackapp/image/upload/f_auto,q_auto:good/v1658826067/static/books/mhl8fezugzxpzaseqi77.png"
            desc={`“KnackApp makes games that identify human potential. There is deep science that goes into the development of these games. KnackApp helps organizations move toward a more diverse working environment by helping eliminate biases. Having people play games and then looking at the data allows organizations to not only move beyond biases but to actually understand the potential candidate in a very deep way, thus truly being able to focus on the best potential person for the job.”`}
            from=""
          />
          <OneSection
            delay={0.3}
            image="https://res.cloudinary.com/knackapp/image/upload/f_auto,q_auto:good/v1658826067/static/books/sbzsko3mlvbrmbj629kl.png"
            desc={`“KnackApp has developed a series of games, each of which generates megabytes of data. By mining the data, KnackApp gets accurate assessments of the players’ creativity, persistence, diligence, and other characteristics that are hard discern from a college transcripts of even face-to-face interview and create better matches and less friction in the employment market.”
`}
            from="The Digital Economy Center at MIT"
          />
        </div>

        <Link
          href="/books"
          className="w-full text-center text-sm font-medium text-[#018906] underline"
        >
          Curious for more? Over 20+ books discuss KnackApp’s pioneering talent
          platform.
        </Link>
      </div>
    </section>
  );
}

function OneSection({
  image,
  desc,
  from,
  delay,
}: {
  image: string;
  desc: string;
  from?: string;
  delay: number;
}) {
  return (
    <motion.div
      initial={{ y: 30 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.3, delay, ease: "easeOut" }}
      viewport={{ once: true, amount: 1 }}
      className="flex max-w-4xl flex-col gap-8 md:flex-row"
    >
      <div className="relative h-[300px] w-full min-w-[150px] md:h-[200px] md:w-[150px]">
        <Image
          src={image}
          fill
          alt={desc}
          className="object-contain object-center md:object-cover"
        />
      </div>
      <div className="flex flex-col gap-6">
        <p
          className={`text-[20px] font-medium leading-[150%] text-[#191919] ${font.className}`}
        >
          {desc}
        </p>
        {from && (
          <span className="text-[20px] font-medium text-[#191919]">{from}</span>
        )}
      </div>
    </motion.div>
  );
}
