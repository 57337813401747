export const testimonials = [
  {
    id: 0,
    text: `KnackApp is a diamond. It’s a game changer in the education-to-employment space that allows us to make an impact.`,
    name: "Managing Director, Education to Employment, Government of Illinois",
    category: "Education to Employment",
  },
  {
    id: 1,
    text: `The smartest app I have ever used. It’s how it profiles you perfectly with its games.`,
    name: "Employee at a Fortune100 company",
    category: "Career Development",
  },
  {
    id: 2,
    text: `Thanks for creating this app. I often feel underpaid and mistreated by having to take a job that doesn’t tap my many talents. I am grateful for having a job but I want and deserve better. I feel like this app is a blessing.`,
    name: `Wilhelmina
North Carolina, USA`,
    category: "",
  },
  {
    id: 3,
    text: `Your company is by far the most impressive I’ve found. I was flabbergasted by the results after playing the games. This is extremeley impressive!`,
    name: "Leader, Employment & Social Development, Government of Canada",
    category: "Education to Employment",
  },
  {
    id: 4,
    text: `I am not trying to flatter your team, but I sincerely believe that what you’ve developed is transformative in nature.`,
    name: "Director of Operations, San Antonio, Texas",
    category: "Recruiting & Hiring",
  },
  {
    id: 5,
    text: `We’ve introduced KnackApp into our school over three years ago to help our students gain self-awareness, discover their talents, and navigate their future career paths. KnackApp enables our educators to transform student engagement and career planning while reducing school dropout.`,
    name: "Edcuation Director, Big Picture Learning",
    category: "Education",
  },
  {
    id: 6,
    text: `Talk about spot on! Don’t know how my playing the games managed to peg me, but they did.`,
    name: "Employee at a Fortune 500 company",
    category: "Career Development",
  },
  {
    id: 7,
    text: `KnackApp helps us identify people’s untapped potential at scale and match them to the most promising work training pathways, driving employability while realizing people's career potential.`,
    name: "CEO, National Skill Development Corporation, India",
    category: "Education to Employment",
  },
  {
    id: 8,
    text: `We use KnackApp to challenge our business students to uncover their most valuable strengths and showcase their soft skills to employers!`,
    name: "Career Development Manager, Politecnico di Milano",
    category: "Career Development",
  },
  {
    id: 9,
    text: `We received wonderful feedback from candidates for the fun experience and positive first impression of our employer brand.`,
    name: `Talent Acquisition Leader
Taj Hotels & Resorts`,
    category: "Recruiting & Hiring",
  },
  {
    id: 10,
    text: `KnackApp boosted our hiring process, helping us identify the hidden leadership potential of candidates and discovering young high potential talent poised to become the future leaders of the Tata Group.`,
    name: `General Manager, Tata Consulting Services`,
    category: "Recruiting & Hiring",
  },
  {
    id: 11,
    text: `We’re using KnackApp in our recruiting program. We’ve now hired and trained over 25 programmers, and after 2 years our retention rate is more than 90%. I credit KnackApp for helping us find talent and allowing us to be confident in their potential over the long term. We’re going to be scaling our hiring programs over the next 3 years to 750 programmers, using KnackApp for every hiring decision.`,
    name: `CEO, NouKòd, Haiti`,
    category: "Recruiting & Hiring",
  },
  {
    id: 12,
    text: `KnackApp changed our career development. We can de-emphasize academic background and job experience, and emphasize people's true talents and potential. KnackApp helps you find out where you have gaps and blind spots so you don’t have to be dependent on others to point out where you fall short. We want to be a successful company tomorrow. This is all about tomorrow.`,
    name: `Head of Human Resources, AXA`,
    category: "Career Development",
  },
  {
    id: 13,
    text: `It’s the best tech investment we ever made. Once we started using KnackApp we never looked back. It’s an integral part of our hiring process. We even use it in our 360’s and HiPO identification to identify talent for internal mobility.`,
    name: `Talent Acquisition Leader, Romania`,
    category: "Recruiting & Hiring",
  },
];
